import React from 'react';
import './style.less';
import Img from 'gatsby-image'

export default class ImageBlock extends React.Component {

    render() {
        return <div className="image-block" data-contained={this.props.imageBlock.contained_display}>
            <div className={this.props.imageBlock.block_type+' caption-'+this.props.imageBlock.caption_location}>
                {this.props.imageBlock.images && this.props.imageBlock.images.map((image) => {
                    return <React.Fragment key={image.id}>
                        { image.localFile.childImageSharp != null ?
                            <Img fluid={image.localFile.childImageSharp.fluid} />
                            :
                            <div className="gatsby-image-wrapper gif">
                                <img src={image.localFile.publicURL} alt=""/>
                            </div>
                        }
                    </React.Fragment>
                })}
                <p>{this.props.imageBlock.caption}</p>
            </div>
        </div>
    }
}
