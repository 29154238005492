import React, { Component } from "react"
import './CaseStudyPost.less';
import {graphql} from "gatsby";
import {Row, Col, Carousel} from 'antd';
import Wrapper from '../components/Wrapper.js';
import Img from "gatsby-image";
import ExternalContent from "../components/ExternalContent";
import ImageBlock from "../components/ImageBlock";
import Link from "../components/Link";
import GoBack from "../components/GoBack";

class CaseStudyPost extends Component {
    render() {
        const caseStudy = this.props.data.wordpressWpCaseStudy;
        const acfFields = caseStudy.acf;

        let image = '';
        if(caseStudy.acf.project_image) image = caseStudy.acf.project_image.localFile.childImageSharp.original.src;
        let schema = [{
            "@context": "http://schema.org/",
            "@type": "Article",
            name: caseStudy.title,
            headline: caseStudy.title,
            description: caseStudy.acf.project_strapline,
            image: "https://www.wearebrew.co.uk" + image,
            datePublished: caseStudy.date,
            dateModified: caseStudy.modified,
            url: `https://www.wearebrew.co.uk/our-work/${caseStudy.slug}/`,
            author: 'Brew Digital',
            publisher: {
                "@type": "Organization",
                name: "Brew Digital",
                logo: "https://www.wearebrew.co.uk/logo.png",
                url: "https://www.wearebrew.co.uk"
            },
            mainEntityOfPage: {
                "@type": "WebPage",
                "@id": caseStudy.acf.project_url,
                url: caseStudy.acf.project_url,
            },
        }];

        let ibCount1 = 0;
        let ibCount2 = 0;
        let ibCount3 = 0;

        return (
            <Wrapper
                title={caseStudy.acf.meta_title || caseStudy.title}
                description={caseStudy.acf.meta_description}
                og_title={acfFields.og_title || caseStudy.acf.meta_title || caseStudy.title}
                og_description={acfFields.og_description || caseStudy.acf.meta_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : caseStudy.acf.project_image.localFile.childImageSharp.original.src}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : caseStudy.acf.project_image.localFile.childImageSharp.original.src}
                schema={schema}
                path={`/our-work/${caseStudy.slug}/`}
            >
                <div className="case-study-wrapper" style={{backgroundColor: caseStudy.acf.project_colour}}>
                    <Row>
                        <Col xs={{span: 24}}>
                            <h1 className="project-strapline">{caseStudy.acf.project_strapline}</h1>
                            <div className="project-image">
                                <Img fluid={caseStudy.acf.project_image.localFile.childImageSharp.fluid} alt={caseStudy.title} />
                            </div>
                        </Col>
                    </Row>

                    { caseStudy.acf.video_url_2 !== '' ?
                        <div className="responsive-video">
                            <iframe src={'https://www.youtube.com/embed/'+caseStudy.acf.video_url_2}
                                    frameBorder="0"
                                    title={caseStudy.title}
                                    allow="accelerometer; encrypted-media;"
                                    allowFullScreen>
                            </iframe>
                        </div>
                        : null }

                    <div className="challenge-block">
                        <Row>
                            <GoBack />
                            <Col xs={{span:24}} md={{span:16}} lg={{span:17}}>
                                <h2><span>01 </span>Challenge</h2>
                                <ExternalContent>
                                    <div dangerouslySetInnerHTML={{__html: caseStudy.acf.challenge}}></div>
                                </ExternalContent>
                            </Col>
                            <Col xs={{span:24}} md={{span:8}} lg={{span:7}}>
                                <div className="client">
                                    <span>Client</span>
                                    <p className="name">
                                        {caseStudy.acf.client_name}
                                    </p>
                                    <p className="site">
                                        <a href={caseStudy.acf.project_url} target="_blank" rel="noopener noreferrer" className="project-link">
                                            <span className="icon"></span>
                                            Visit website
                                        </a>
                                    </p>
                                </div>
                                <div className="services">
                                    <span>Services</span>
                                    <p>{caseStudy.acf.services && caseStudy.acf.services.map((service) => {
                                        return <Link key={'css-key-'+service.value} to={'/our-work/'+service.value}>{service.label}</Link>
                                    })}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        {caseStudy.acf.client_logo !== null ?
                        <Col className="client-logo">
                            <Img fluid={caseStudy.acf.client_logo.localFile.childImageSharp.fluid} />
                        </Col>
                            : null }
                    </Row>

                    {caseStudy.acf.image_blocks && caseStudy.acf.image_blocks.map((imageBlock) => {
                        ibCount1++
                        return <ImageBlock key={'csib1-key-'+ibCount1} imageBlock={imageBlock} />
                    })}

                    <div className="approach-block">
                        <Row>
                            <Col xs={{span:24}}>
                                <h2><span>02 </span>Approach</h2>
                                <ExternalContent>
                                    <div dangerouslySetInnerHTML={{__html: caseStudy.acf.approach}}></div>
                                </ExternalContent>
                            </Col>
                        </Row>
                    </div>

                    {caseStudy.acf.image_blocks_2 && caseStudy.acf.image_blocks_2.map((imageBlock) => {
                        ibCount2++
                        return <ImageBlock key={'csib1-key-'+ibCount2} imageBlock={imageBlock} />
                    })}

                    {caseStudy.acf.testimonials !== null ?
                        <div className="testimonials">
                            <Row>
                                <Col xs={{span:24}}>
                                    <Carousel dots arrows autoplaySpeed={5000} autoplay swipe swipeToSlide>
                                        {caseStudy.acf.testimonials && caseStudy.acf.testimonials.map((testimonial) => {
                                            return <div className="slide" key={'slide_' + testimonial.id}>
                                                <div className="photo">
                                                    <Img fluid={testimonial.image.localFile.childImageSharp.fluid} />
                                                </div>
                                                <div className="quote">
                                                    <p>{ testimonial.quote }</p>
                                                    <p className="cite">{ testimonial.cite }</p>
                                                </div>
                                            </div>
                                        })}
                                    </Carousel>
                                </Col>
                            </Row>
                        </div>
                    : null }

                    {caseStudy.acf.image_blocks_3 && caseStudy.acf.image_blocks_3.map((imageBlock) => {
                        ibCount3++
                        return <ImageBlock key={'csib1-key-'+ibCount3} imageBlock={imageBlock} />
                    })}

                    { caseStudy.acf.video_url !== '' ?
                        <div className="responsive-video">
                            <iframe src={'https://www.youtube.com/embed/'+caseStudy.acf.video_url}
                                    frameBorder="0"
                                    title={caseStudy.title}
                                    allow="accelerometer; encrypted-media;"
                                    allowFullScreen>
                            </iframe>
                        </div>
                    : null }

                    <div className="outcome-block">
                        <Row>
                            <Col xs={{span:24}}>
                                <h2><span>03 </span>Outcome</h2>
                                <ExternalContent>
                                    <div dangerouslySetInnerHTML={{__html: caseStudy.acf.outcome}}></div>
                                </ExternalContent>
                            </Col>
                            {caseStudy.acf.project_stats && caseStudy.acf.project_stats.length && caseStudy.acf.project_stats.map((projectStat) => {
                                return <Col key={projectStat.icon.id} className="project-stat" xs={{span:24}} md={{span:8}}>
                                    <div className="icon">
                                        <img src={projectStat.icon.localFile.childImageSharp.original.src} alt="" />
                                    </div>
                                    <p>{projectStat.text}</p>
                                </Col>
                            })}
                        </Row>
                    </div>
                </div>

                <Row className="further-actions-blurb">
                    <Col xs={{span: 24}}>
                        <h2>Like what you see?</h2>
                        <p dangerouslySetInnerHTML={{__html: caseStudy.acf.further_actions_blurb}}></p>
                    </Col>
                </Row>

                <Row>
                    <div className="further-actions">
                        <a className="contact" href="/contact-us/">
                            <span className="block">Work with us?</span>
                            <span className="arrow">Contact us</span>
                        </a>
                        { caseStudy.acf.previous_project !== '' ?
                            <a className="previous" href={caseStudy.acf.previous_project}>
                                <span className="block">Previous project</span>
                                <span className="arrow">Our previous project</span>
                            </a>
                            : null }
                        { caseStudy.acf.next_project !== '' ?
                            <a className="next" href={caseStudy.acf.next_project}>
                                <span className="block">Next project</span>
                                <span className="arrow">Our next project</span>
                            </a>
                            : null }
                    </div>
                </Row>
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressWpCaseStudy(id: { eq: $id }) {
            id
            slug
            title
            date
            modified
            acf {
                meta_title
                meta_description
                og_title
                og_description
                og_image {
                  localFile {
                    childImageSharp {
                      original {
                        width
                        height
                        src
                      }
                    }
                  }  
                }
                twitter_title
                twitter_card_type
                twitter_description
                twitter_image {
                  localFile {
                    childImageSharp {
                      original {
                        width
                        height
                        src
                      }
                    }
                  }  
                }  
                project_strapline
                project_image {
                    localFile {
                        childImageSharp {
                            fluid(maxHeight: 400, quality: 90) {
                                ... GatsbyImageSharpFluid
                            }
                            original {
                                src
                            }
                        }
                    }
                }
                project_colour
                video_url_2
                challenge
                client_name
                client_logo {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 160, quality: 90) {
                                ... GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                project_url
                services {
                    value
                    label
                }
                image_blocks {
                    contained_display
                    block_type
                    images {
                        id
                        mime_type
                        localFile {
                            publicURL
                            childImageSharp {
                                original {
                                    width
                                    height
                                    src
                                }
                                fluid(maxWidth: 1920, quality: 90) {
                                    ... GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    caption
                    caption_location
                }
                approach
                image_blocks_2 {
                    contained_display
                    block_type
                    images {
                        id
                        mime_type
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1920, quality: 90) {
                                    ... GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    caption
                    caption_location
                }
                testimonials {
                    quote
                    cite
                    image {
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxHeight: 500, quality: 90) {
                                    ... GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                image_blocks_3 {
                    contained_display
                    block_type
                    images {
                        id
                        mime_type
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1920, quality: 90) {
                                    ... GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    caption
                    caption_location
                }
                video_url
                outcome
                project_stats {
                    text
                    icon {
                        id
                        localFile {
                            childImageSharp {
                                original {
                                    width
                                    height
                                    src
                                }
                                fluid(maxHeight: 150) {
                                    ... GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                further_actions_blurb
                next_project
                previous_project
            }
        }
    }`

export default CaseStudyPost


